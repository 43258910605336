import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TopBar from "../../../../components/TopBar";
import { faCheck, faCircleCheck, faCircleXmark, faMobileAlt, faSearch, faTruck, faUnlock, faX } from "@fortawesome/free-solid-svg-icons";

import './styles.css';
import { getStoredJson, sleep, useInitialCheck } from "../../../../utils";
import { editDatabaseByCondition, editDataBaseRow, getDataBaseCondition, getDatabaseLogs, getDatabaseRows, getServerGraphs } from "../../../../utils/database";
import CustomHalfDonutChart from "../../../../components/Graphs/CustomHalfDonutChart";
import CustomLinePlot from "../../../../components/Graphs/CustomLineChart";
import CustomPieChart from "../../../../components/Graphs/CustomPieChart";
import RenderMap from "../../../../components/Graphs/Map";
import { DateSelector, formatTimestamp, sortArrayDatetime } from "../../../../components/DatePicker";
import { Table, TableCell, TableRow } from '../../../../components/Table';
import LoadingDiv from "../../../../components/LoadingDiv";
import CustomDropBox from "../../../../components/CustomDropBox";
import ScrollView from "../../../../components/ScrollView";
import { standardData, emptyData } from "../sample";
import CustomModal from "../../../../components/Modal";
import LoadingCircle from "../../../../components/LoadingCircle";

export default function ControlPage(){

    const user = getStoredJson('user', {})
    const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false)
    
    const [inicialDate, setInitialDate] = useState<string>('')
    const [finalDate, setFinalDate] = useState<string>('')
    const [webData, setWebData] = useState<any>(standardData)
    
    const [update, setUpdate] = useState<boolean>(false)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [modalUpdate, setModalUpdate] = useState<boolean>(false)
    const [successfullCmd, setSuccessfullCmd] = useState<boolean>(false)

    const [selectedPlate, setSelectedPlate] = useState<any>({})
    const [selectedCompany, setSelectedCompany] = useState<any>({})
    const [unlockedPlate, setUnlockedPlate] = useState<any>({})

    const [companies, setCompanies] = useState<any>([])
    const [plates, setPlates] = useState<any>([])
    const [suntechs, setSuntechs] = useState<any>([])
    const [filteredEtl, setFilteredEtl] = useState<any>([])

    const [logs, setLogs] = useState<any>([])
    const [lastSync, setLastSync] = useState<any>([])

    const [randomTests, setRandomTests] = useState<any>()
    const [tests, setTests] = useState<any>([])
    const [totalTests, setTotalTests] = useState<any>()

    const [unblockedTest, setUnblockedTest] = useState<any>([])
    const [blockedTest, setBlockedTest] = useState<any>([])
    const [postponedTest, setPostponedTest] = useState<any>([])
    const [noBlowTest, setNoBlowTest] = useState<any>([])

    const [events, setEvents] = useState<any>([])

    const [selectedBlockOption, setSelectedBlockOption] = useState<any>([])

    const blockOptions = [
        {label: 'Desbloquear', key: 'has_to_unblock'},
        {label: 'Bloquear', key: 'has_to_block'}
    ]

    const navigate = useNavigate()
    //useInitialCheck(importData)
    //useInitialCheck(updateDash)

    useEffect(() => {
        getLogs()
    }, [])


    async function updateDash() {
        setUpdate(true)

        //await importGraphs()
        await filterLogs(logs)

        await sleep(500)
        setUpdate(false)
    }

    async function getLogs() {
        setUpdate(true); 

        const logs = await importLogs(); 
        await importData();
        await filterLogs(logs); 

        await sleep(500)
        setUpdate(false);
    }

    async function importData(){
        const companies = await importCompanies()
        await importPlates(companies)
    }

    async function importLogs(){
        const response = await getDatabaseLogs();
        let rows       = []
        let filteredRows = []

        for(let item of response){
            for(let row of item.event.split('\r\n')){
                row = row.trim()

                if(row.length > 0 && item.etilometer.telemetry.id === '44922499000'){
                    
                    rows.push({...item, event: row})
                }
            }
        }

        if (user.company !== 'SIGHIR ENTERPRISE LTDA')
            filteredRows = rows.filter((item: any) => item.etilometer.device.company.label === user.company)
         else {
            setIsSuperAdmin(true)
            filteredRows = rows
         }    

        const sortedLogs = sortArrayDatetime(filteredRows, 'timestamp');
        setLogs(sortedLogs);
        return sortedLogs;
    }

    async function filterLogs(logs: any) {
        let time: { [key: string]: number } = {};
        let random = 0;
        let unblocked = 0;
        let blocked = 0;
        let postponed = 0;
        let noBlow = 0;
        let totalTests = 0;
        let events: any[] = [];
        let lastLog = ''
        let initDate: Date
        let finDate: Date

        if (inicialDate){
            initDate = new Date(inicialDate)

            logs = logs.filter((item: any) => new Date(item.timestamp) > initDate )
        }

        if (finalDate){
            finDate = new Date(finalDate)

            logs = logs.filter((item: any) => new Date(item.timestamp) < finDate )
        }
            
        if (selectedCompany && Object.keys(selectedCompany).length > 0)
            logs = logs.filter((item: any) => item.etilometer.device.company.id === selectedCompany.id)
        
        if (selectedPlate && Object.keys(selectedPlate).length > 0)
            logs = logs.filter((item: any) => item.etilometer.vehicle_plate === selectedPlate.label)
        
        logs.forEach((item: any) => {
            let ev = item.event.split('\r\n')[0];
    
            if (ev === '$ETEV16!') {
                const date = item.timestamp.split(' ')[0];
    
                if (time[date]) {
                    time[date] += 1;
                } else {
                    time[date] = 1
                }

                totalTests += 1 
            }
    
            if (ev === '$ETEV25!') 
                random += 1;
            
            if (ev === '$ETEV29!') 
                unblocked += 1;

            if (ev.includes('ETEV30'))
                blocked += 1;

            if (ev === '$ETEV11!') 
                noBlow += 1;

            if (ev === '$ETEV15!') {
                postponed += 1;

                if (lastLog === '$ETEV12!'){
                    events.push({
                        event: 'Leitura Adiada no Randômico',
                        vehiclePlate: item.etilometer.vehicle_plate,
                        date: formatTimestamp(item.timestamp)
                    });
                } else {
                    events.push({
                        event: 'Leitura Adiada',
                        vehiclePlate: item.etilometer.vehicle_plate,
                        date: formatTimestamp(item.timestamp)
                    });
                }
            }

            if (ev.includes('ETEV30')) {
                const match = ev.match(/300(\d+)!/)
                let value
                if (match && match[1]) {
                    value = '(0.' + match[1] + ' mg/L)'
                    events.push({
                        event: 'Álcool Detectado ' + value,
                        vehiclePlate: item.etilometer.vehicle_plate,
                        date: formatTimestamp(item.timestamp)
                    });
                }
            }

            if (ev === '$ETEV33!')
                events.push({
                    event: 'Teste Randômico Não Realizado',
                    vehiclePlate: item.etilometer.vehicle_plate,
                    date: formatTimestamp(item.timestamp)
                });

            if (ev === '$ETEV28!')
                events.push({
                    event: 'Temperatura Muito Alta',
                    vehiclePlate: item.etilometer.vehicle_plate,
                    date: formatTimestamp(item.timestamp)
                });

            if (ev === '$ETEV21!')
                events.push({
                    event: 'Sensor Vencido',
                    vehiclePlate: item.etilometer.vehicle_plate,
                    date: formatTimestamp(item.timestamp)
                });

            lastLog = ev

            console.log(JSON.stringify(ev));
        });
        
        let lastSync = logs[0] ? logs[0].timestamp : 'NA'
        setLastSync(lastSync)

        setTests(time)
        setRandomTests(random)
        setTotalTests(totalTests)

        setUnblockedTest(unblocked)
        setBlockedTest(blocked)
        setPostponedTest(postponed)
        setNoBlowTest(noBlow)

        setEvents(events)
    }
    

    async function importCompanies(){
        //const response = getStoredJson('companies', [])
        let response = await getDatabaseRows('etilometers')
        response = response.filter((item: any) => item.telemetry.id === '44922499000')
        const target   = response.map((item: any) => ({label: item.device.company.label, id: item.device.company.id}))

        setCompanies(target)
        setFilteredEtl(response)
        return response
    }

    async function importPlates(response: any){
        let target   = response.map((item: any) => ({label: item.vehicle_plate, company: item.device.company.label}))
        if (!isSuperAdmin)
            target = target.filter((item: any) => item.company === user.company)
        setPlates(target)
    }

    function renderLeftContainer() {
        return (
            <ScrollView className='ControlContainer' style={{ width: '28%', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' }}>
                <div style={{ width: '100%', height: '15%', display: 'flex', justifyContent: 'space-between' }}>
                    <div className="carIconContainer" style={{ backgroundColor: 'gray' }}>
                        <FontAwesomeIcon icon={faTruck} className="carIcon" size='sm' />
                    </div>

                    <div className="carIconContainer" onClick={() => navigate('/Etilometro/Sensor')}>
                        <FontAwesomeIcon icon={faMobileAlt} className="carIcon" />
                    </div>
                </div>

                <div style={{ padding: '1rem' }} />

                <div style={{ width: '100%' }}>
                    <CustomDropBox
                        options={plates}
                        value={selectedPlate}
                        setValue={setSelectedPlate}
                        placeholder='Placa do Veículo'
                    />
                </div>

                <div style={{ padding: '.7rem' }} />

                {isSuperAdmin && (
                    <>
                    <div style={{ width: '100%' }}>
                        <CustomDropBox
                            options={companies}
                            value={selectedCompany}
                            setValue={setSelectedCompany}
                            placeholder='Empresa'
                        />
                    </div>

                    <div style={{ padding: '.7rem' }} />
                    </>
                )}

                

                <div style={{ width: '100%' }}>
                    <DateSelector
                        value={inicialDate}
                        onChange={setInitialDate}
                        placeholder='Data Inicial'
                    />
                </div>

                <div style={{ padding: '.7rem' }} />

                <div style={{ width: '100%' }}>
                    <DateSelector
                        value={finalDate}
                        onChange={setFinalDate}
                        placeholder='Data Final'
                    />
                </div>

                <div style={{ padding: '1rem' }} />

                <button 
                    className='buttonInfoContainer' 
                    onClick={async () => await updateDash()} 
                    style={{width: '50%', opacity: update ? 0.6 : 1, cursor: update ? 'not-allowed' : 'pointer'}}
                    disabled={update}
                >
                    <FontAwesomeIcon icon={faSearch} style={{ paddingRight: '7%' }} />
                    <p style={{ fontSize: 'calc(5px + .5vw)' }}>Consultar</p>
                </button>
            </ScrollView>
        )
    }

    function renderPieLabel(label: string, color: string) {
        return (
            <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: color }} />

                <div style={{ width: '85%', fontSize: 'calc(5px + .5vh)', color: 'white' }}>
                    {label}
                </div>
            </div>
        )
    }

    function formatEvents(){
        let data: any = []

        events.forEach((item: any) => {
            data.push(
                <TableRow>
                    <TableCell>{item.vehiclePlate}</TableCell>
                    <TableCell>{item.event}</TableCell>
                    <TableCell>{item.date}</TableCell>
                </TableRow>
            )
            
        });

        return data
    }

    function handleModal() {
        setModalOpen(!modalOpen)
        setModalUpdate(false)
        setSuccessfullCmd(false)
    }

    function renderRightContainer() {
        return (
            <div className='ControlContainer' style={{ width: '60%', justifyContent: 'space-between', padding: 0 }}>
                <div style={{ display: 'flex', width: '100%', height: '15%', justifyContent: 'space-between' }}>
                    <LoadingDiv loading={update} className='controlItem' style={{ width: '31%' }}>
                        <span>
                            Último Sincronismo
                        </span>
                        <br />
                        <span>
                            {lastSync ? formatTimestamp(lastSync) : ''}
                        </span>
                    </LoadingDiv>

                    <LoadingDiv loading={update} className='controlItem' style={{ width: '31%' }}>
                        <span>
                            Percentual de Randômicos
                        </span>
                        <br />
                        <div style={{ color: 'white', fontSize: 'calc(6px + .6rem)' }}>
                        {isFinite((randomTests / totalTests) * 100) 
                        ? ((randomTests / totalTests) * 100).toFixed(2) + '%' 
                        : 'NA'}
                        </div>
                    </LoadingDiv>

                    <LoadingDiv loading={update} className='controlItem' style={{ width: '31%' }}>
                        <span>
                            Alterar Estado de Bloqueio Remotamente
                        </span>       

                        <br/>

                        <div className="unlockIconContainer" onClick={() => handleModal()}>
                            <FontAwesomeIcon icon={faUnlock} className="unlockIcon" size='sm' />
                        </div>     
                    </LoadingDiv>
                </div>

                <div style={{ display: 'flex', width: '100%', height: '30%', justifyContent: 'space-between' }}>
                    <LoadingDiv loading={update} className='controlItem' style={{ width: '31.5%', }}>
                        <div style={{ width: '100%', fontSize: 'calc(5px + .6vw)' }}>
                            Quantidade de Testes Realizados
                        </div>

                        <div style={{ padding: '12px' }} />

                        <div style={{ display: 'flex', width: '100%', flex: 1 }}>
                            <CustomLinePlot time={tests} />
                        </div>
                    </LoadingDiv>

                    <LoadingDiv loading={update} className='controlItem' style={{ width: '25%' }}>
                        <div style={{ color: 'white' }}>
                            Total de Testes Randômicos
                        </div>

                        <div style={{ display: 'flex', width: '100%', flex: 1, alignItems: 'center' }}>
                            <div className="graphContainer">
                                <CustomHalfDonutChart
                                    data={[
                                        { name: 'Testes Randomicos', value: randomTests },
                                        { name: 'Testes Iniciais', value: totalTests - randomTests },
                                    ]}
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', width: '80%', justifyContent: 'space-between' }}>
                            <div>{0}</div>
                            <div>{(totalTests - randomTests) / 2}</div>
                            <div>{totalTests}</div>
                        </div>
                    </LoadingDiv>

                    <LoadingDiv loading={update} className='controlItem' style={{ width: '38%', }}>
                        <div style={{ color: 'white' }}>
                            Resultados de Testes
                        </div>

                        <div style={{ padding: '2px' }} />
                                    
                        <CustomPieChart data={[
                            { 
                                name: 'Bloqueado (álcool)',
                                value: blockedTest,
                                color: '#ff6961'
                            },
                            { 
                                name: 'Liberado', 
                                value: unblockedTest, 
                                color: '#00ff58' 
                            },
                            { 
                                name: 'Sem Sopro', 
                                value: noBlowTest, 
                                color: '#004819'
                            },
                            { 
                                name: 'Adiado', 
                                value: postponedTest, 
                                color: 'blue'
                            },
                        ]}
                        />

                        <div style={{ width: '100%', color: 'gray', justifyContent: 'space-between', display: 'flex' }}>
                            <div style={{ width: '31%' }}>
                                {renderPieLabel('Bloqueado (álcool)', '#ff6961')}
                            </div>

                            <div style={{ width: '31%' }}>
                                {renderPieLabel('Liberado', '#00ff58')}
                            </div>

                            <div style={{ width: '31%' }}>
                                {renderPieLabel('Sem Sopro', '#004819')}
                            </div>

                            <div style={{ width: '31%' }}>
                                {renderPieLabel('Adiado', 'blue')}
                            </div>
                        </div>

                        <div style={{ padding: '2px' }} />
                    </LoadingDiv>
                </div>

                <div style={{ display: 'flex', width: '100%', height: '45%', justifyContent: 'space-between' }}>

                    <LoadingDiv loading={update} className='controlItem' style={{ width: '48%' }}>
                        
                                <div style={{ color: 'white' }}>
                                    Últimos Eventos de Alerta
                                </div>

                                <div style={{ padding: '5px' }} />

                                <Table>
                                    <TableRow>
                                        <TableCell>Placa</TableCell>
                                        <TableCell>Evento</TableCell>
                                        <TableCell>Data</TableCell>
                                    </TableRow>

                                    {formatEvents()}
                                </Table>
                    </LoadingDiv>

                    <LoadingDiv loading={update} style={{ display: 'flex', flexDirection: 'column', width: '49%', height: '100%', justifyContent: 'space-between' }}>
                        <div
                            className='controlItem'
                            style={{ width: '100%', height: '100%', justifyContent: 'center' }}
                        >
                            <div style={{ color: 'white' }}>
                                Etilômetros instalados
                            </div>

                            <div style={{ padding: '5px' }} />

                            <div style={{ width: '100%', flex: 1 }}>
                                <RenderMap data={webData.installed_devices_graph} />
                            </div>
                        </div>
                    </LoadingDiv>

                    
                </div>
            </div>
        )
    }

    async function handleVehicleUnlock() {
        let selectedEtl = filteredEtl.filter((item: any) => item.vehicle_plate === unlockedPlate.label)

        let selectedSuntech = await getDataBaseCondition('suntechs', 'id', selectedEtl[0].device.suntech)

        let newData = {
            [selectedBlockOption.key]: true
        };

        

        if (selectedSuntech && selectedSuntech[0].is_connected === false){
            setModalUpdate(true)

            setSuccessfullCmd(false)
            setUnlockedPlate({})
            setSelectedBlockOption([])

            return
        }

        let response = await editDataBaseRow('suntechs', newData, selectedSuntech[0].id);

        if (response.status !== 'success') {
            setModalUpdate(true)

            setSuccessfullCmd(false)
            setUnlockedPlate({})
            setSelectedBlockOption([])

            return
        }

        setModalUpdate(true)
        setSuccessfullCmd(true)

        setUnlockedPlate({})
        setSelectedBlockOption([])
        
        //setModalUpdate(false)
        //setModalOpen(!modalOpen)
    }

    return (
        <div className="MainContainer">
            <CustomModal modalOpen={modalOpen} className='unlockModal'>
                <div style={{paddingTop: '3%', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <span style={{color: 'white', fontSize: '20px', marginLeft: '19.3%'}}>Alterar Estado de Bloqueio Remotamente</span>

                    <span style={{color: 'white', fontSize: '20px', display: 'flex', marginRight: '3%', cursor: 'pointer'}} onClick={() => handleModal()}>X</span>
                </div>

                {!modalUpdate && (
                    <>
                        <div style={{ marginTop: '3%' , width: '40%' }}>
                            <CustomDropBox
                                options={blockOptions}
                                value={selectedBlockOption}
                                setValue={setSelectedBlockOption}
                                placeholder='Comando'
                            />
                        </div>

                        <div style={{ marginTop: '3%' , width: '40%' }}>
                            <CustomDropBox
                                options={plates}
                                value={unlockedPlate}
                                setValue={setUnlockedPlate}
                                placeholder='Placa do Veículo'
                            />
                        </div>

                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <button 
                                className='buttonUnlock' 
                                onClick={async () => await handleVehicleUnlock()} 
                                style={{marginTop: '5%', width: '40%', opacity: Object.keys(unlockedPlate).length === 0 || Object.keys(selectedBlockOption).length === 0 ? 0.6 : 1, cursor: Object.keys(unlockedPlate).length === 0 || Object.keys(selectedBlockOption).length === 0 ? 'not-allowed' : 'pointer'}}
                                disabled={Object.keys(unlockedPlate).length === 0 || Object.keys(selectedBlockOption).length === 0}
                            >
                                <p style={{ fontSize: 'calc(5px + .5vw)' }}>Enviar</p>
                            </button>
                        </div>
                    </>
                )}

                {modalUpdate && (
                    <>
                        <FontAwesomeIcon icon={successfullCmd ? faCircleCheck : faCircleXmark} 
                         className="carIcon" size='sm' 
                         style={{ marginTop: '10%', borderRadius: '5rem', backgroundColor: successfullCmd ? '#00dd4d' : 'red'}}/>
                    
                        <span style={{color: 'white', fontSize: '17px', marginTop: '5%'}}>
                            {successfullCmd ? `Comando enviado com sucesso!` : `Comando não enviado! Veículo desconectado.`}
                        </span>
                    </>
                    
                )}
            </CustomModal>

            <TopBar />
            <div style={{ padding: '1rem' }} />

            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                {renderLeftContainer()}

                <div style={{ padding: '1.5%' }} />

                {renderRightContainer()}
            </div>

        </div>
    )
}