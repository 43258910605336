
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faHeadset, 
    faChartPie,
    faFileLines,
    faClipboardList,
    faTriangleExclamation,
    faDownload,
    faUnlockKeyhole,
    faFilm
} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

import './styles.css'
import '../global.css'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { Carousel } from '../components/Carousel'

import app1 from '../../../assets/img/app/1.jpeg'
import app2 from '../../../assets/img/app/2.jpeg'
import app3 from '../../../assets/img/app/3.jpeg'
import app4 from '../../../assets/img/app/4.jpeg'
import app5 from '../../../assets/img/app/5.jpeg'
import mixLogo from '../../../assets/img/logo/mix.png'
import logikaLogo from '../../../assets/img/logo/logika.png'
import prediletoLogo from '../../../assets/img/logo/predileto-branco.png'
import unitracLogo from '../../../assets/img/logo/unitrac.png'
import movieitLogo from '../../../assets/img/logo/movieit.png'
import siteDashboard from '../../../assets/img/site_dashboard.png'
import security from '../../../assets/img/start_security.jpg'
import integration from '../../../assets/img/start_integration.jpg'
import { Slider } from '../components/Slider'

export default function StartPage() {
    const appImgs = [app1, app2, app3, app4, app5]
    const partnersLogos = [
        {
            img: mixLogo,
            size: 50
        }, 
        {
            img: logikaLogo,
            size: 100
        }, 
        {
            img: prediletoLogo,
            size: 100
        },
        {
            img: unitracLogo,
            size: 40
        },
        {
            img: movieitLogo,
            size: 60
        }
    ]

    const navigate = useNavigate()

    return (
        <div id='container'>
            <Header/>

            <div id='content'>
                <div id='first-content'>
                    <p className='subsection-title'>Sua segurança, nosso compromisso</p>
                    <p className='subsection-subtitle'>
                        Oferecemos soluções que proporcionam autonomia e tecnologia <br/>
                        para transformar a segurança no trânsito.
                    </p>

                    <button 
                        onClick={() => navigate('products')}
                        className='primary-button'
                    >
                        Saiba mais
                    </button>
                </div>

                <div id='product-section' className='section'>
                    <p className='section-title'>Produtos</p>

                    <section className='subsection'>
                        <div className='subsection-header'>
                            <p className='subsection-title'>Adicione mais segurança ao seu transporte rodoviário</p>
                            <p className='subsection-subtitle'>
                                Oferecemos soluções que proporcionam autonomia e tecnologia <br/>
                                para transformar a segurança no trânsito.
                            </p>
                        </div>

                        <div className='image-list-container'>
                            <div className='img-image-list'>
                                <img src={security}/>
                            </div>

                            <div className='advantage-list'>
                                <div>
                                    <p>Monitoramento de alta precisão</p>
                                    <p>
                                        Parametrização, testes randômicos e mais: integrações com
                                        dispositivos de telemetria e câmeras.
                                    </p>
                                </div>

                                <div>
                                    <p>Sensores de alta precisão</p>
                                    <p>
                                        Sensores de álcool e de pressão embutidos no sistema com alta precisão e respostas rápidas
                                    </p>
                                </div>

                                <div>
                                    <p>Sensor de temperatura</p>
                                    <p>
                                        Equipado com um sensor de temperatura, o sistema emite alertas imediatos em situações de 
                                        superaquecimento, com tolerância de até 55ºC. 
                                    </p>
                                </div>

                                <div>
                                    <p>Manutenção simples</p>
                                    <p>
                                        Sensores substituíveis e fácil integração com sistemas existentes.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section className='subsection'>
                        <div className='subsection-header'>
                            <p className='subsection-title'>Monitore sua frota</p>
                            <p className='subsection-subtitle'>
                                O painel do cliente fornece todos os dados que seu operacional precisa<br/>
                                para gerenciar manutenções e verificar índices de sua frota
                            </p>
                        </div>

                        <img
                            src={siteDashboard}
                            style={{ width: '100%', borderRadius: 5 }}
                        />

                        <div id='advantage-table'>
                            
                                <div>
                                    <FontAwesomeIcon
                                        icon={faHeadset}
                                        size='2xl'
                                        color='#00ff58'
                                    />

                                    <p>Monitoramento de eventos</p>
                                </div>

                                <div>
                                    <FontAwesomeIcon
                                        icon={faChartPie}
                                        size='2xl'
                                        color='#00ff58'
                                    />

                                    <p>Dashboards</p>
                                </div>

                                <div>
                                    <FontAwesomeIcon
                                        icon={faFileLines}
                                        size='2xl'
                                        color='#00ff58'
                                    />

                                    <p>Manuais de uso</p>
                                </div>

                                <div>
                                    <FontAwesomeIcon
                                        icon={faClipboardList}
                                        size='2xl'
                                        color='#00ff58'
                                    />

                                    <p>Relatórios de instalação</p>
                                </div>

                            
                                <div>
                                    <FontAwesomeIcon
                                        icon={faTriangleExclamation}
                                        size='2xl'
                                        color='#00ff58'
                                    />

                                    <p>Avisos de manutenção</p>
                                </div>

                                <div>
                                    <FontAwesomeIcon
                                        icon={faDownload}
                                        size='2xl'
                                        color='#00ff58'
                                    />

                                    <p>Atualização remota</p>
                                </div>

                                <div>
                                    <FontAwesomeIcon
                                        icon={faUnlockKeyhole}
                                        size='2xl'
                                        color='#00ff58'
                                    />

                                    <p>Dispositivos ativados</p>
                                </div>

                                <div>
                                    <FontAwesomeIcon
                                        icon={faFilm}
                                        size='2xl'
                                        color='#00ff58'
                                    />

                                    <p>Vídeos de instrução</p>
                                </div>
                        </div>
                    </section>

                    <section className='subsection'>
                        <div className='subsection-header'>
                            <p className='subsection-title'>Aplicativo móvel<br/>para seu operacional</p>
                            <p className='subsection-subtitle'>
                                Manutenção na palma das mãos
                            </p>
                        </div>

                        <Slider
                            imgs={appImgs}
                            imgContainerClass='app-carousel-item'
                        />
                    </section>

                    <section className='subsection'>

                        <div className='subsection-header'>
                            <p className='subsection-title'>Integração flexível e prática para sua frota</p>
                        </div>

                        <div className='image-list-container'>
                            <div className='advantage-list'>
                                <div>
                                    <p>Modo sensor</p>
                                    <p>
                                        Nossos produtos possuem 2 formas de comunicação para atuar como acessório a dispositivos embarcados já presentes nos veículos com a tecnologia Bluetooth e Wifi
                                    </p>
                                </div>

                                <div>
                                    <p>Modo autônomo</p>
                                    <p>
                                        Também disponibilizamos um modo que se comunica através de sinais elétricos do veículo, sem depender se nenhum dispositivo externo
                                    </p>
                                </div>

                                <div>
                                    <p>Sistema anti-fraude</p>
                                    <p>
                                        Os sistemas anti-fraude incluem a integração com sistemas de câmera e a realização de re-testes durante a viagem, proporcionando uma camada adicional de segurança e confiabilidade.
                                    </p>
                                </div>
                            </div>

                            <div className='img-image-list'>
                                <img src={integration}/>
                            </div>
                        </div>

                        <button
                            className='primary-button'
                            style={{
                                alignSelf: 'flex-start',
                                marginTop: 40
                            }}
                        >
                            Saiba mais
                        </button>
                    </section>

                </div>

                <div className='section'>
                    <p className='subsection-title'>Parceiros</p>
                    
                    <div style={{ 'width': '100%' }}>
                        <Slider
                            imgs={partnersLogos}
                            imgContainerClass='partners-carousel-item'
                        />
                    </div>
                </div>

                <div className='section' id='visite-us-section'>
                    <div className='subsection-header'>
                        <p className='section-title'>Infraestrutura</p>
                        <p className='subsection-title'>Visite nossa fábrica</p>
                        <p className='subsection-subtitle'>
                            Infraestrutura avançada para te atender
                        </p>
                    </div>
                    
                    <div>
                        <p>Como chegar</p>
                        
                        <div>
                            <iframe 
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3681.451478577358!2d-43.2992493238792!3d-22.674231129602706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x99722e9363d917%3A0xca2fd879032900d3!2sExpresso%20Predileto%20Transportes!5e0!3m2!1spt-BR!2sbr!4v1720027167729!5m2!1spt-BR!2sbr" 
                                width="100%" 
                                height="450" 
                                style={{ border: 0 }} 
                                allowFullScreen={false} 
                                loading="lazy" 
                                referrerPolicy="no-referrer-when-downgrade"
                            />
                        </div>
                    </div>
                </div>
        
            </div>

            <Footer/>
        </div>
    )
}