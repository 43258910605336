
import './styles.css'

export function Policy() {
    return (
        <div id='container'>
            <main className='content-container' id='policy-container'>
                <p>Política de Privacidade</p>

                <p>
                    A SIGHIR está comprometida com a proteção da privacidade de nossos usuários
                    e a segurança dos dados coletados por nossos dispositivos de etilômetros.
                    Nós coletamos apenas os dados essenciais para a operação eficaz do etilômetro 
                    e respeitamos rigorosamente a legislação vigente sobre proteção de dados. 
                    Para garantir a funcionalidade do etilômetro, coletamos dados de login e fotos
                    tiradas durante o processo de instalação (via app) do dispositivo. Estas fotos ajudam a 
                    confirmar que a instalação foi realizada corretamente e são armazenadas de forma segura, 
                    usadas exclusivamente para fornecer suporte e manter a qualidade dos nossos serviços.
                    <br/><br/>
                    Todas as informações coletadas são usadas exclusivamente dentro do escopo dos serviços
                    prestados pela SIGHIR e não são compartilhadas com terceiros fora do domínio da empresa,
                    exceto conforme exigido por lei. Implementamos medidas de segurança robustas para proteger
                    os dados contra acesso não autorizado, alteração, divulgação ou destruição. O tráfego de dados
                    dentro de nossa rede é criptografado, assegurando a segurança das informações transmitidas.
                    <br/><br/>
                    Em caso de dúvidas ou preocupações sobre nossa política de privacidade ou práticas de dados,
                    entre em contato conosco pelo e-mail: contato@sighir.com.br
                </p>
            </main>
        </div>
    )
}