import './styles.css'

type CarouselProps = {
    sources: any[];
    type: 'image'|'video'
}

export function Carousel({ sources, type }: CarouselProps) {

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        const target = document.querySelector(e.currentTarget.getAttribute('href') || '');
        if (target) {
            target.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    };
    
    const isImage = type === 'image'

    return (
        <div id='carousel-container'>
            <div id='carousel-wrapper'>
                <div id='carousel-slider'>
                    {
                        isImage ?
                        sources.map((src, index) => <img id={`slide-img-${index}`} src={src}/>) :
                        sources.map((src, index) => <video id={`slide-video-${index}`} src={src} controls/>)
                    }
                </div>

                <div id='carousel-nav'>
                    { 
                        sources.map((src, index) => (
                            <a 
                                href={`#slide-${isImage ? 'img' : 'video'}-${index}`} 
                                onClick={handleClick}
                            >
                            </a>
                        )) 
                    }
                </div>
            </div>

        </div>
    )
}