import './styles.css'
import logo from '../../../../assets/img/logoSIGHIR.png'
import iff_logo from '../../../../assets/img/iff_macae.png'
import coppe_logo from '../../../../assets/img/coppe.png'
import embrapii_logo from '../../../../assets/img/embrapii.png'


import video_app from '../../../../assets/video/video_app.mp4'
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import ScrollView from '../../../../components/ScrollView';


export default function AppInfoPage(){
    const videoRef = useRef<any>(null);
    const [isPlaying, setIsPlaying] = useState(false)

    function handlePlayPause(){
        if(isPlaying)
            videoRef.current.pause()
        else
            videoRef.current.play()

        setIsPlaying(prev => !prev)
    }

    return (
        <ScrollView className='MainContainer'>
            <div className='AppInfo'>
                <div className='TitleContainer'>
                    Sighir Breathalyzer Mobile App
                </div>

                <div className='ItemsContainer' style={{height: '40vh'}}>
                    <div className='VideoContainer' onClick={handlePlayPause} style={{height: '100%'}}>
                        <video 
                            src={video_app} 
                            ref={videoRef} 
                            style={{display: 'flex', height: '100%', borderRadius: '1rem'}}
                        />
                        
                        <FontAwesomeIcon 
                            icon={faPlay} 
                            className='VideoButton' 
                            color={isPlaying ? 'transparent' : 'white'}
                        /> 
                    </div>

                    <ScrollView className='InfoContainer' style={{height: '100%'}}>
                        <img src={logo} style={{height: '4vh', display: 'flex'}}/>
                        <div style={{padding: '.5rem'}}/>

                        <div style={{display: 'flex', flexDirection: 'column', width: '90%', alignSelf: 'center'}}>
                            <div style={{fontWeight: 'bold', display: 'flex'}}>Sobre</div>
                            <div style={{padding: '.3rem'}}/>

                            <div style={{display: 'flex'}}>Etilômetro Veicular</div>
                            <div style={{padding: '.1rem'}}/>

                            <div style={{display: 'flex', marginLeft: '10px'}}>1. Confiabilidade</div>
                            <div style={{display: 'flex', marginLeft: '10px'}}>2. Segurança</div>
                            <div style={{display: 'flex', marginLeft: '10px'}}>3. Monitoramento</div>

                            <div style={{padding: '.3rem'}}/>
                            <div style={{display: 'flex', textAlign: 'justify'}}>
                                O aplicativo SIGHIR facilita a instalação e o suporte técnico
                                de etilômetros, permitindo gestão simplificada e acesso rápido a testes e ajustes.
                            </div>
                        </div>
                    </ScrollView>
                </div>

                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{color: 'white', fontSize: '1.7rem'}}>
                        Contato
                    </div>

                    <div style={{padding: '5px'}}/>

                    <div style={{color: 'white'}}>
                        sighir@gmail.com
                    </div>
                </div>
                
                <div style={{display: 'flex', width: '100%', height: '11vh', justifyContent: 'center'}}>
                    <div style={{display: 'flex', width: '70%', height: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                        <img src={iff_logo} alt='' height='75%'/>

                        <img src={coppe_logo} alt='' height='70%'/>

                        <img src={embrapii_logo} alt='' height='100%'/>
                    </div>
                </div>
            </div>
        </ScrollView>
    )
}