import { getDeadline } from "../../../components/DatePicker";

export const standardData = {
    "last_sync": getDeadline(new Date()),
    "total_randomic_percentage": 43,
    "random_tests_graph": {
        "min": 89,
        "max": 112,
        "val": 21
    },
    "tests_results_graph": {
        "blocked": 20,
        "allowed": 60,
        "no_blow": 5,
        "postponed": 80
    },
    "tests_graph": {
        "x": [
            "2024-09-02",
            "2024-09-03",
            "2024-09-04",
            "2024-09-05",
            "2024-09-06",
            "2024-09-07",
            "2024-09-08",
            "2024-09-09",
            "2024-09-10",
            "2024-09-11",
            "2024-09-12",
            "2024-09-13",
            "2024-09-14",
            "2024-09-15",
            "2024-09-16"
        ],
        "y": [8, 18, 22, 26, 25, 2, 1, 7, 79, 57, 55, 34, 17, 2, 18],
        "t": ["2024-09-02", "2024-09-09", "2024-09-16"]
    },
    "eventsTable": {
        "$ETEV15!": [
            {
                "plate": "LUE9A29",
                "event": "$ETEV17!",
                "timestamp": "2024-09-16 13:47:31"
            },
            {
                "plate": "LUE9A29",
                "event": "$ETEV17!",
                "timestamp": "2024-09-16 13:47:31"
            },
            {
                "plate": "LUE9A29",
                "event": "$ETEV17!",
                "timestamp": "2024-09-16 13:47:31"
            },
            {
                "plate": "LUE9A29",
                "event": "$ETEV17!",
                "timestamp": "2024-09-16 13:47:31"
            },
            {
                "plate": "LUE9A29",
                "event": "$ETEV17!",
                "timestamp": "2024-09-16 13:47:31"
            },
            {
                "plate": "LUE9A29",
                "event": "$ETEV17!",
                "timestamp": "2024-09-16 13:47:31"
            },
            {
                "plate": "LUE9A29",
                "event": "$ETEV17!",
                "timestamp": "2024-09-16 13:47:31"
            },
            {
                "plate": "LUE9A29",
                "event": "$ETEV17!",
                "timestamp": "2024-09-16 13:47:31"
            },
            {
                "plate": "LUE9A29",
                "event": "$ETEV17!",
                "timestamp": "2024-09-16 13:47:31"
            },
            {
                "plate": "LUE9A29",
                "event": "$ETEV17!",
                "timestamp": "2024-09-16 13:47:31"
            },
            {
                "plate": "LUE9A29",
                "event": "$ETEV17!",
                "timestamp": "2024-09-16 13:47:31"
            },
            {
                "plate": "LUE9A29",
                "event": "$ETEV17!",
                "timestamp": "2024-09-16 13:47:31"
            },
            {
                "plate": "LUE9A29",
                "event": "$ETEV17!",
                "timestamp": "2024-09-16 13:47:31"
            },
            {
                "plate": "LUE9A29",
                "event": "$ETEV17!",
                "timestamp": "2024-09-16 13:47:31"
            },
            {
                "plate": "LUE9A29",
                "event": "$ETEV17!",
                "timestamp": "2024-09-16 13:47:31"
            },
            {
                "plate": "LUE9A29",
                "event": "$ETEV17!",
                "timestamp": "2024-09-16 13:47:31"
            },
            {
                "plate": "LUE9A29",
                "event": "$ETEV17!",
                "timestamp": "2024-09-16 13:47:31"
            }
        ],
        "$ETEV30!": [],
        "$ETEV21!": [],
        "$ETEV27!": [],
        "$ETEV33!": []
    },
    "total_travels": 3798,
    "average_travel_time": 1.07,
    "total_distance": 157395.64,
    "installed_devices_graph": [
        {
            "lat": -22.7858,
            "lng": -43.3117,
            "label": "DUQUE DE CAXIAS"
        },
        {
            "lat": -22.7858,
            "lng": -43.3117,
            "label": "MACA\u00c9"
        }
    ]
}

export const emptyData = {
    'total_travels': 0,
    'average_travel_time': 0,
    'total_distance': 0,
    "last_sync": getDeadline(new Date()),
    'total_randomic_percentage': 0,
    "eventsTable": {
        "$ETEV15!": [],
        "$ETEV30!": [],
        "$ETEV21!": [],
        "$ETEV27!": [],
        "$ETEV33!": []
    },
    'installed_devices_graph': [],
    'random_tests_graph': {
        'min': 0,
        'val': 0,
        'max': 0,
    },
    'tests_graph': {
        x: [],
        y: []
    },
    'tests_results_graph': {
        'blocked':   [],
        'allowed':   [],
        'no_blow':   [],
        'postponed': []
    },
}