export const goodParse = (text: string, float=false) => {
    text += ' '
    const num = text.split(' ')[0]
    return float ? Number.parseFloat(num) : Number.parseInt(num)
}


export const getParameter = (key: string, desired: any, numericInput=true) => {
    const settings: any = {
        comm_type: {
            'Padrão': 0,
            'Bluetooth': 1,
            'Serial': 2,
        },

        operation_mode: {
            'Sensor': 0,
            'Autônomo': 1
        },

        vehicle_type: {
            'Caminhão': 0,
            'Carro': 1,
        },

        enable_random: {
            'Não': 0,
            'Sim': 1
        },

        camera: {
            'Não': 0,
            'Sim': 1
        },
    }

    const params = settings[key]

    if(params == null || params == undefined)
        return null

    if(!numericInput){
        if(params[desired] == null || params[desired] == undefined)
            return null
        
        return params[desired]
    }

    for(let [key, value] of Object.entries(params))
        if(value == desired)
            return key

    return null
}
