import { useState } from 'react';
import { Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { useInterval } from '../../../utils';

export default function CustomHalfDonutChart({ data = [], constWidth = 40, constHeight = 25 }: any) {
	const [width, setWidth] = useState(window.innerWidth)
	const [height, setHeight] = useState(window.innerHeight)

	useInterval(updateDimensions, 200)
	const propDimWidth = Math.floor(Math.sqrt(width * width + height * height)) / constWidth
	const propDimHeight = Math.floor(Math.sqrt(width * width + height * height)) / constHeight

	function updateDimensions() {
		if (window.innerWidth != width)
			setWidth(window.innerWidth)

		if (window.innerHeight != height)
			setHeight(window.innerHeight)
	}

	const COLORS = ['#004819', '#24ff6f', '#00ff58', '#004819']

	return (
		<ResponsiveContainer width="100%" height={'100%'}>
			<PieChart>
				<Pie
					data={data}
					cx="50%"
					cy="90%"
					startAngle={180}
					endAngle={0}
					innerRadius={propDimWidth}
					outerRadius={propDimHeight}
					fill="#8884d8"
					paddingAngle={1}
					dataKey="value"
				>
					{
						data.map((entry: any, index: any) => (
							<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
						))
					}
				</Pie>
				<Tooltip />
			</PieChart>
		</ResponsiveContainer>
	);
}
