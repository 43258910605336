import { useState } from 'react';
import { Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { useInterval } from '../../../utils';

export default function CustomPieChart({data=[], propConst=0.025, hasData}: any) {
    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)
    
    useInterval(updateDimensions, 200)
    const propDim = Math.floor(Math.sqrt(width*width + height*height)) * propConst

    function updateDimensions(){
        if(window.innerWidth != width)
            setWidth(window.innerWidth)

        if(window.innerHeight != height)
            setHeight(window.innerHeight)
    }

    const defaultData = [
        { name: 'Não há dados', value: 1, color: 'grey' },
    ]

    if (hasData == false){
        data = defaultData
    }

    const COLORS = ['#b6ffcf', '#24ff6f', '#00ff58']

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={data}
                    cx="50%"
                    cy="50%"
                    outerRadius={propDim}
                    fill="#8884d8"
                >
                    {
                        data.map((entry: any, index: any) => <Cell key={`cell-${index}`} fill={entry.color}/>)
                    }
                </Pie>
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>
    );
}
