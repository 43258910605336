import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.css'
import {useState} from 'react'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'


const SimpleDropDown = ({options, selected, setSelected, className='', style={}, textClass='', icon=false, iconStyle=false, textColor='black', placeholder='', itemClass='', bgColor='transparent'}: any) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropDownStyle = !isOpen ? {} : {borderBottomLeftRadius: 0, borderBottomRightRadius: 0, backgroundColor: bgColor}
    
    return (
        <div className={className} style={{zIndex: 99, ...style}}>
            <div
                className="dropDownContainer" 
                onClick={() => setIsOpen(!isOpen)} 
                style={{
                    ...dropDownStyle, 
                    color: textColor
                }}
            >
                {icon && <FontAwesomeIcon icon={icon} style={{ ...iconStyle}}/>}

                <div className="dropDownText">
                    <div className={textClass}>
                        {selected ? selected : placeholder}
                    </div>
                </div>

                <div style={{padding: '5px'}}/>

                <FontAwesomeIcon icon={faAngleDown} className="downArrow" style={{...iconStyle, alignSelf: 'center'}}/>

                {isOpen && (
                    <div className="dropdown-content">
                        {options.map((option: any, index: any) => (
                            <div 
                                key={index} 
                                className="dropdown-item" 
                                onClick={() => setSelected(option)}
                                style={{
                                    borderBottomLeftRadius: (index == options.length - 1) ? '.6rem' : '0px',
                                    borderBottomRightRadius: (index == options.length - 1) ? '.6rem' : '0px',
                                }} 
                            >
                                <div className={itemClass}>
                                    {option}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default SimpleDropDown