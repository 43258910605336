
import './styles.css'

import { Footer } from '../components/Footer'
import { Header } from '../components/Header'

import logo from '../../../assets/img/logo/sighir_preto.png'
import ourHistory from '../../../assets/img/history.png'
import security from '../../../assets/img/our_values_security.jpg'
import innovation from '../../../assets/img/our_values_innovation.jpg'
import eficiency from '../../../assets/img/our_values_eficiency.jpg'

export function AboutUs() {
    return (
        <div id='container'>
            <Header />

            <div className='content-container'>
                <div>
                    <img 
                        src={logo} 
                        style={{
                            height: '15vw',
                            maxHeight: 70
                        }}
                    />
                    <p>Sua segurança, nosso compromisso</p>
                </div>

                <div className='content-section' style={{ marginTop: 100 }}>
                    <p className='section-title'>Sobre nós</p>

                    <p style={{ textAlign: 'start' }}>
                        Somos uma startup de tecnologia dedicada a desenvolver soluções avançadas para a segurança nas estradas. Nosso principal produto é o detector
                        de álcool, desenvolvido para monitorar a sobriedade dos motoristas e garantir condições de condução mais seguras. Esforçamo-nos para integrar
                        perfeitamente com os sistemas veiculares, fornecendo medidas de segurança confiáveis e eficientes.
                    </p>

                    <section className='subsection'>
                        <div className='subsection-header'>
                            <p className='paragraph'>
                                Na SIGHIR, investimos constantemente em novas tecnologias para criar produtos que promovam a segurança rodoviária de forma eficiente e confiável. Nossa equipe está sempre em busca de novas soluções para os desafios da segurança no trânsito.
                            </p>
                        </div>

                        <p className='catchphrase'>
                            Startup nacional de tecnologia impulsionando a inovação em segurança
                        </p>
                    </section>

                    <section className='subsection'>
                        <div className='subsection-header'>
                            <p className='subsection-title'>Valores</p>
                        </div>

                        <div id='our-values'>
                            <div>
                                <div className='our-values-img'>
                                    <img src={security}/>
                                </div>

                                <div className='our-values-content'>
                                    <p>Segurança</p>
                                    <p>
                                        A segurança é a nossa prioridade. Nosso objetivo é proteger vidas, especialmente considerando os alarmantes dados de mortes causadas por álcool no trânsito.
                                        A diversidade de regulamentações de segurança exige tecnologia avançada para um monitoramento eficaz. Na SIGHIR, estamos comprometidos em desenvolver soluções
                                        inovadoras que atendam a essas necessidades, transcendendo barreiras tecnológicas e culturais. Com a nossa tecnologia, trabalhamos para reduzir acidentes e
                                        salvar vidas, garantindo um futuro mais seguro para todos nas estradas.
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div className='our-values-content'>
                                    <p>Inovação</p>
                                    <p>
                                        A inovação nos impulsiona para explorar novas possibilidades e nos motiva a resolver os desafios de segurança. Desde a invenção até a aplicação,
                                        nossas tecnologias rapidamente promovem operações mais seguras, eficientes e sustentáveis para nossos clientes. Com nosso compromisso contínuo com
                                        a inovação, garantimos que cada desenvolvimento tecnológico contribua para um trânsito mais seguro e confiável.
                                    </p>
                                </div>

                                <div className='our-values-img'>
                                    <img src={innovation}/>
                                </div>
                            </div>

                            <div>
                                <div className='our-values-img'>
                                    <img src={eficiency}/>
                                </div>

                                <div className='our-values-content'>
                                    <p>Eficiência</p>
                                    <p>
                                        Na SIGHIR, a eficiência é um valor central. Nosso compromisso é entregar soluções tecnológicas que não só maximizem o desempenho
                                        e a segurança nas estradas, mas também garantam uma satisfação plena para nossos clientes. Oferecemos suporte eficiente e desenvolvemos
                                        novos produtos com resultados superiores e qualidade avançada. A eficiência em nossas operações resulta diretamente em menor número de
                                        acidentes e na melhoria da qualidade do trânsito, proporcionando tranquilidade e segurança para todos os nossos usuários.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className='content-section' id='our-history'>
                        <p className='section-title'>Nossa história</p>

                        <img 
                            src={ourHistory} 
                            style={{ 
                                width: '100%',
                                marginTop: 40,
                                marginBottom: 40 
                            }} 
                        />

                        <p>
                            O projeto SIGHIR iniciou em 2020 com testes de conceito, utilizando caixas de prototipagem para garantir apenas a funcionalidade dos dispositivos.
                            Foi desenvolvido em parceria com diversas organizações de estudo, desenvolvimento e tecnologia: <b>Polo de Inovação do IFF, COPPE UFRJ, Embrapii e Sebrae</b>.
                            <br/><br/>
                            Em 2021, ano em que foi criado o primeiro protótipo e iniciado a fase um do projeto. Nesse mesmo ano foi validado o teste de conceito.
                            Ao final do ano, após o teste de conceito, foi feita a primeira instalação em um caminhão. Em 2022 foi iniciado a fase dois do projeto,
                            ano de aprimoramento do protótipo e apresentação de melhorias significativas, resultando em parcerias estratégicas para finalização do projeto, ano que nasce a STARTUP.
                            <br/><br/>
                            Em 2024, o primeiro produto da SIGHIR tomou forma e está à venda. Mas não paramos por aqui. Com profissionais altamente qualificados, queremos ir ainda mais longe.
                            Já estamos desenvolvendo pelo menos três novos produtos para aumentar a segurança nas vias. Nossa equipe dedicada continua inovando para garantir que nossas soluções
                            ofereçam a melhor proteção possível para todos os usuários das estradas.

                        </p>
                        
                        <div>
                            <p className='subsection-title'>Parcerias e Reconhecimento</p>
                            <p>Nossa jornada já nos levou a participar de importantes feiras e eventos, incluindo:</p>
                            <ul>
                                <li>Rio Oil & Gás 2022</li>
                                <li>Transposul 2023</li>
                                <li>Inscrição no Prêmio Shell</li>
                                <li>Semana Nacional de Ciência e Tecnologia (SNCT) 2023</li>
                                <li>Rio Innovation Week 2023</li>
                                <li>Fórum Nacional de Segurança Rodoviária (FNSR) 2024</li>
                                <li>Parceria com MIX Telematics</li>
                            </ul>   
                        </div>
                    </section>

                    <section className='content-section'>
                        <p className='section-title'>Nossa fábrica</p>
                        <img
                            height={200}
                            width='100%'
                        />
                    </section>
                </div>
            </div>

            <Footer/>
        </div>
    )
}