import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';    

import { deleteJsonData, getStoredJson, saveJsonData } from "./utils";
import HomePage from "./pages/Etilometro/Home";
import LoginPage from "./pages/Etilometro/Login/Login";
import InfoPage from "./pages/Etilometro/Info/Info";
import SoftwarePage from "./pages/Etilometro/Software";
import ControlPage from "./pages/Etilometro/Control/Truck";
import ProceduresPage from "./pages/Etilometro/Documentation/Procedures";
import ManualsPage from "./pages/Etilometro/Documentation/Manuals";
import DatasheetPage from "./pages/Etilometro/Documentation/Datasheet";
import VideosPage from "./pages/Etilometro/Documentation/Videos";
import InstalationPage from "./pages/Etilometro/Reports/Instalation";
import SensorPage from "./pages/Etilometro/Control/Sensor";
import CalibrationPage from "./pages/Etilometro/Reports/Calibration";
import AnexosPage from "./pages/Etilometro/Documentation/Anexos";
import AppInfoPage from "./pages/Etilometro/Info/AppInfo";
import StartPage from "./pages/Sighir/Start";

import { AboutUs } from "./pages/Sighir/AboutUs";
import { Certifications } from "./pages/Sighir/Certifications";
import { Policy } from "./pages/Sighir/Policy";
import { Products } from './pages/Sighir/Products';
import { ContactUs } from './pages/Sighir/ContactUs';


function ProtectedRoute({children}: any){
    const user = getStoredJson('user', {})

    if(!user.timestamp){
        deleteJsonData('user')
        return (<Navigate to="/Etilometro"/>)
    }
    
    if (Date.now() - user.timestamp > 200*60*1000){ // desloga após 200 min
        deleteJsonData('user')
        return (<Navigate to="/Etilometro"/>)
    }
    
    saveJsonData('user', {...user, timestamp: Date.now()})
    return children;
}

export default function AppRoutes(){
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/'  element={<StartPage/>}/>

                <Route path="/about" element={<AboutUs/>}/>
                <Route path="/certifications" element={<Certifications/>}/>
                <Route path="/policy" element={<Policy/>}/>
                <Route path='/products' element={<Products/>}/>
                <Route path='/contact-us' element={<ContactUs/>}/>


                <Route path='/Etilometro'             element={<LoginPage/>}/>
                <Route path='/Etilometro/Home'        element={<ProtectedRoute><HomePage/></ProtectedRoute>}/>
                <Route path='/Etilometro/Info'        element={<ProtectedRoute><InfoPage/></ProtectedRoute>}/>
                <Route path='/Etilometro/Software'    element={<SoftwarePage/>}/>
                <Route path='/Etilometro/Control'     element={<ProtectedRoute><ControlPage/></ProtectedRoute>}/>
                <Route path='/Etilometro/Videos'      element={<ProtectedRoute><VideosPage/></ProtectedRoute>}/>
                <Route path='/Etilometro/Datasheet'   element={<ProtectedRoute><DatasheetPage/></ProtectedRoute>}/>
                <Route path='/Etilometro/Manuals'     element={<ProtectedRoute><ManualsPage/></ProtectedRoute>}/>
                <Route path='/Etilometro/Procedures'  element={<ProtectedRoute><ProceduresPage/></ProtectedRoute>}/>
                <Route path='/Etilometro/Anexos'      element={<ProtectedRoute><AnexosPage/></ProtectedRoute>}/>
                <Route path='/Etilometro/Instalation' element={<ProtectedRoute><InstalationPage/></ProtectedRoute>}/>
                <Route path='/Etilometro/Calibration' element={<ProtectedRoute><CalibrationPage/></ProtectedRoute>}/>
                <Route path='/Etilometro/Sensor'      element={<ProtectedRoute><SensorPage/></ProtectedRoute>}/>
                <Route path='/Etilometro/AppInfo'     element={<AppInfoPage/>}/>
            </Routes>
        </BrowserRouter>
    )
}

