import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faSearch, faAngleLeft, faAngleRight, faFilePdf, faSheetPlastic } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';
import LoadingDiv from '../../../components/LoadingDiv';
import { getParameter } from '../../../utils/settings';
import TopBar from '../../../components/TopBar';
import { getDatabaseLogs, getDatabaseRows, getWebData } from '../../../utils/database';
import CustomDropBox from '../../../components/CustomDropBox';
import { Table, TableCell, TableRow } from '../../../components/Table';
import { saveJsonData, handleExportExcel, handleExportPDF, sleep, getStoredJson } from '../../../utils';
import { DateSelector, formatTimestamp, isTimestampBetween, sortArrayDatetime } from '../../../components/DatePicker';

const events = [
    {label: 'Não reconhecido', value: 'ETNACK'},
    {label: 'Etilômetro Iniciado', value: 'ETEV08'},
    {label: 'Sem Sopro', value: 'ETEV11'},
    {label: 'Randomico', value: 'ETEV12'},
    {label: 'Sensor Inválido', value: 'ETEV19'},
    {label: 'Adiado', value: 'ETEV15'},
    {label: 'Sensor Liberado', value: 'ETEV20'},
    {label: 'Teste Realizado', value: 'ETEV16'},
    {label: 'Valor Analógico', value: 'analog'}
]

const companyTypes = [
    { label: 'Transportadora', value: 'transportation' },
    { label: 'Rastreamento', value: 'telemetry' }
]

type ItemType = {
    label: string,
    value: string
}

export default function HomePage(){

    const user = getStoredJson('user', {})
    const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false)

    const [logs, setLogs] = useState<any>([])
    const [rows, setRows] = useState<any>([])

    const [vehicles, setVehicles] = useState<any>([])
    const [companies, setCompanies] = useState<ItemType[]>([])
    const [selectedVehicle, setSelectedVehicle] = useState<any>({})

    const [filteredVehicles, setFilteredVehicles] = useState<any>([])
    const [filteredCompanies, setFilteredCompanies] = useState<ItemType[]>([])
    
    const [selectedEvent, setSelectedEvent] = useState<any>({})
    const [selectedCompany, setSelectedCompany] = useState<any>({})
    const [selectedCompanyType, setSelectedCompanyType] = useState<any>({})

    const [inicialDate, setInitialDate] = useState<string>('')
    const [finalDate, setFinalDate] = useState<string>('')
    
    const [showItems, setShowItems] = useState([0, 30])
    const [update, setUpdate] = useState<boolean>(true)

    useEffect(() => {
        importLogs()
    }, [])

    useEffect(() => {
        getCompanies()
        getVehicles()
    }, [logs])

    useEffect(() => {
        if (update)
            updateRows()
    }, [update, logs])

    useEffect(() => {
        filterData()
    }, [selectedCompany, selectedCompanyType])

    const navigate = useNavigate()
    
    async function importLogs(){
        //const response = await getDatabaseRows('logs')
        const response = await getDatabaseLogs();
        let rows       = []
        let filteredRows = []

        for(let item of response){
            for(let row of item.event.split('\r\n')){
                row = row.trim()

                if(row.length > 0 && item.etilometer.telemetry.id === '44922499000')
                    rows.push({...item, event: row})

                //alert(JSON.stringify(item))
            }
        }

        if (user.company !== 'SIGHIR ENTERPRISE LTDA')
            filteredRows = rows.filter((item: any) => item.etilometer.device.company.label === user.company)
        else{
            filteredRows = rows
            setIsSuperAdmin(true)
         }  

        setLogs(sortArrayDatetime(filteredRows, 'timestamp'))
    }

    function getCompanies() {
        const uniqueCompanies: { [key: string]: boolean } = {}
        const companiesInLogs = []

        for (const log of logs) {
            const c = log.etilometer.device.company

            if (!uniqueCompanies[c.label]) {
                uniqueCompanies[c.label] = true
                companiesInLogs.push({ label: c.label, value: c.id, type: c.type })
            }
        }
        
        for (const log of logs) {
            const c = log.etilometer.telemetry
            
            if (!uniqueCompanies[c.label]) {
                uniqueCompanies[c.label] = true
                companiesInLogs.push({ label: c.label, value: c.id, type: c.type })
            }
        }

        setCompanies(companiesInLogs)
        setFilteredCompanies(companiesInLogs)
    }

    function getVehicles(){
        const uniqueCompanies: { [key: string]: boolean } = {}
        const vehiclesInLogs = []

        for (const log of logs) {
            const plate = log.etilometer.vehicle_plate

            if (!uniqueCompanies[plate]) {
                uniqueCompanies[plate] = true
                vehiclesInLogs.push({ label: plate, company: log.etilometer.device.company.id, telemetry: log.etilometer.telemetry.id })
            }
        }

        setVehicles(vehiclesInLogs)
        setFilteredVehicles(vehiclesInLogs)
    }

    function filterData() {
        if (Object.keys(selectedCompanyType).length > 0)
            setFilteredCompanies(companies.filter((item: any) => item.type === selectedCompanyType.value))
        else
            setFilteredCompanies(companies)

        if (Object.keys(selectedCompany).length > 0)
            setFilteredVehicles(vehicles.filter((item: any) => item.company === selectedCompany.value || item.telemetry === selectedCompany.value))
        else
            setFilteredVehicles(vehicles)
    }

    async function updateRows() {
        await sleep(500)

        if (logs.length == 0)
            return

        let data = logs
        
        if(selectedCompany.label)
            data = data.filter((item: any) => item.etilometer.device.company.label.toLowerCase() == selectedCompany.label.toLowerCase())
            
        if(selectedVehicle.label)
            data = data.filter((item: any) => item.etilometer.vehicle_plate.toLowerCase() == selectedVehicle.label.toLowerCase())

        if(selectedEvent.label)
            data = data.filter((item: any) => item.event.toLowerCase().indexOf(selectedEvent.value.toLowerCase()) != -1)

        if(inicialDate.length > 0 || finalDate.length > 0)
            data = data.filter((item: any) => isTimestampBetween(item.timestamp, inicialDate, finalDate))
        
        if (selectedCompanyType.label)
            data = data.filter((item: any) => item.etilometer.device.company.type === selectedCompanyType.value)

        setRows(data)
        setUpdate(false)
    }

    function handlePrevious(){
        let start = showItems[0]
        let end   = showItems[1]

        start -= 30
        end -= 30

        if (end > rows.length) {
            end = rows.length
            start = rows.length - 30
        }

        if (start < 0) {
            start = 0
            end = 30
        }

        setShowItems([start, end])
    }

    function handleNext(){
        let start = showItems[0]
        let end   = showItems[1]

        start += 30
        end  += 30

        if(end > rows.length){
            end = rows.length
            start = rows.length - 30
        }

        if(start < 0){
            start = 0
            end   = 30
        }

        setShowItems([start, end])
    }

    function renderInputs(){
        return (
            <div className="DataFillContainer" data-is-super-admin={isSuperAdmin}>
                <div>
                    <div>
                        <DateSelector
                            value={inicialDate}
                            onChange={setInitialDate}
                            placeholder='Data Inicial'
                        />
                    </div>

                    <div>
                        <DateSelector
                            value={finalDate}
                            onChange={setFinalDate}
                            placeholder='Data Final'
                        />
                    </div>
                </div>

                {isSuperAdmin && (
                    <div>
                        <div>
                            <CustomDropBox
                                options={companyTypes}
                                value={selectedCompanyType}
                                setValue={setSelectedCompanyType}
                                placeholder='Tipo de Empresa'
                            />
                        </div>
                        
                        <div>
                            <CustomDropBox
                                options={filteredCompanies}
                                value={selectedCompany}
                                setValue={setSelectedCompany}
                                placeholder='Empresa'
                            />
                        </div>
                    </div>
                )}

                <div>
                    <div>
                        <CustomDropBox
                            options={filteredVehicles}
                            value={selectedVehicle}
                            setValue={setSelectedVehicle}
                            placeholder='Placa do Veículo'
                        />
                    </div>

                    <div>
                        <CustomDropBox
                            options={events}
                            value={selectedEvent}
                            setValue={setSelectedEvent}
                            placeholder='Evento'
                        />
                    </div>
                </div>
            </div>
        )
    }

    function handleItemSelect(item: any){
        if(!item)
            return

        saveJsonData('infoLog', item)
        navigate('/Etilometro/Info')
    }
    
    return (
        <div className="MainContainer">
            <TopBar/>

            <div className='CentralBlock'>
                <div style={{padding: '.5rem'}}/>

                {renderInputs()}

                <div className='HomeInfoContainer'>
                    <button className='buttonInfoContainer' onClick={() => setUpdate(prev => !prev)}>
                        <FontAwesomeIcon icon={faSearch} style={{paddingRight: '7%'}}/>
                        <p style={{fontSize: 'calc(8px + .5vw)'}}>Consultar</p>
                    </button>

                    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                        <Table>
                            <TableRow id='main-table-header'>
                                <TableCell>Placa do veículo</TableCell>
                                <TableCell>Empresa</TableCell>
                                <TableCell>Tipo de veículo</TableCell>
                                <TableCell>Eventos</TableCell>
                                <TableCell>Data do recebimento do evento</TableCell>
                            </TableRow>

                            {update && <div style={{padding: '.7rem'}}/>}

                            <LoadingDiv loading={update}>
                                {
                                    rows.slice(showItems[0], showItems[1]).map((item: any) => {
                                        const company = item.etilometer.device.company
                                        if( item.event.startsWith('$') && item.event.endsWith('!') && item.event )
                                        return (
                                            <TableRow onClick={() => handleItemSelect(item)}>
                                                <TableCell>{item.etilometer.vehicle_plate}</TableCell>
                                                <TableCell>{company.label}</TableCell>
                                                <TableCell>{getParameter('vehicle_type', item.etilometer.vehicle_type)}</TableCell>
                                                <TableCell>{item.event}</TableCell>
                                                <TableCell>{formatTimestamp(item.timestamp)}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </LoadingDiv>
                        </Table>
                    </div>
                    
                    <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div className='buttonsHomeNav'/>
                                    
                        <div style={{display: 'flex', width: '30%', minWidth: 200, justifyContent: 'space-between'}}>
                            <button className='buttonInfoContainer' style={{width: '45%'}} onClick={() => handleExportExcel(rows)}>
                                <FontAwesomeIcon icon={faSheetPlastic} style={{paddingRight: '7%'}}/>
                                <p style={{fontSize: 'calc(8px + .4vw)'}}>Exportar Excel</p>
                            </button>

                            <button className='buttonInfoContainer' style={{width: '45%'}} onClick={() => handleExportPDF(rows)}>
                                <FontAwesomeIcon icon={faFilePdf} style={{paddingRight: '7%'}}/>
                                <p style={{fontSize: 'calc(8px + .4vw)'}}>Exportar PDF</p>
                            </button>
                        </div>
                        
                        <div className='buttonsHomeNav'>
                            <button 
                                className='arrowButton' 
                                onClick={handlePrevious}
                                disabled={showItems[0] <= 0}
                            >
                                <FontAwesomeIcon icon={faAngleLeft} color='white'/>
                            </button>
                                
                            <button 
                                className='arrowButton' 
                                onClick={handleNext}
                                disabled={showItems[1] > (rows.length - showItems[0])}
                            >
                                <FontAwesomeIcon icon={faAngleRight} color='white'/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}