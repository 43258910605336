
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

import './styles.css'
import TopBar from '../../../../components/TopBar'
import { getStoredJson } from '../../../../utils'
import { getDataBaseCondition } from '../../../../utils/database'
import LoadingDiv from '../../../../components/LoadingDiv'
import { formatTimestamp } from '../../../../components/DatePicker'
import { getParameter } from '../../../../utils/settings'

export default function InfoPage() {
    const [relatedSensorData, setRelatedSensorData] = useState<any>({})
    const [update, setUpdate] = useState<boolean>(true)

    let stored = getStoredJson('infoLog', {})
    stored = stored.etilometer !== undefined ? stored.etilometer : stored
    const defaultSettings = stored.default_settings
    const etilometerConfig = defaultSettings !== 'None' ? JSON.parse(defaultSettings) : {}

    useEffect(() => {
        importDeviceLogs()
    }, [])

    async function importDeviceLogs() {
        const response = await getDataBaseCondition('sensors', 'sensor_id', stored.device.sensor_id)

        if (response.length == 0)
            return

        let data: any = {}

        const lastLog = response.reduce((maisRecente: any, objetoAtual: any) => {
            const dataMaisRecente = new Date(maisRecente.timestamp);
            const dataAtual = new Date(objetoAtual.timestamp);

            return dataAtual > dataMaisRecente ? objetoAtual : maisRecente;
        })

        data.number_of_tests = response.length
        data.last_sync = lastLog.timestamp
        data.last_read = lastLog.mgl.toFixed(2) + ' mg/L'

        setRelatedSensorData(data)
        setUpdate(false)
    }

    function renderLeftContainer() {
        return (
            <div className='leftContainer'>
                <div className='leftItemContainer' style={{ height: '32%' }}>
                    <FontAwesomeIcon icon={faUser} style={{ fontSize: '3vw' }} color='white' />
                    <div style={{ padding: '4%' }} />

                    <LoadingDiv loading={update} style={{ display: 'flex', flexDirection: 'column', height: '80%', width: '100%', justifyContent: 'space-between', overflow: 'hidden' }}>
                        <div />
                        <div />

                        <div className='infoText' style={{ fontWeight: 'bold' }}>
                            {stored.vehicle_plate}
                        </div>

                        <div className='infoText'>
                            Tipo de Veículo: {stored.vehicle_type}
                        </div>

                        <div className='infoText'>
                            Última Sincronização: {formatTimestamp(relatedSensorData.last_sync)}
                        </div>

                        <div />
                        <div />
                    </LoadingDiv>
                </div>

                <div className='leftItemContainer' style={{ cursor: 'not-allowed', opacity: .6 }}>
                    <FontAwesomeIcon icon={faUser} style={{ fontSize: '2vw' }} color='white' />
                    <div style={{ padding: '4%' }} />

                    <LoadingDiv loading={update} className='infoText' style={{ fontWeight: 'bold' }}>
                        Viagens
                    </LoadingDiv>
                </div>

                <div className='leftItemContainer' style={{ cursor: 'not-allowed', opacity: .6 }}>
                    <FontAwesomeIcon icon={faUser} style={{ fontSize: '2vw' }} color='white' />
                    <div style={{ padding: '4%' }} />

                    <LoadingDiv loading={update} className='infoText' style={{ fontWeight: 'bold' }}>
                        Atualizações
                    </LoadingDiv>
                </div>

                <div className='leftItemContainer' style={{ cursor: 'not-allowed', opacity: .6 }}>
                    <FontAwesomeIcon icon={faUser} style={{ fontSize: '2vw' }} color='white' />
                    <div style={{ padding: '4%' }} />

                    <LoadingDiv loading={update} className='infoText' style={{ fontWeight: 'bold' }}>
                        Manutenções
                    </LoadingDiv>
                </div>
            </div>
        )
    }

    function renderOption(titulo: string, valor: string) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '4%' }}>
                <div style={{ color: 'white', fontWeight: 'bold', textAlign: 'left', fontSize: '1rem' }}>
                    {titulo}
                </div>

                <div style={{ color: 'white', textAlign: 'left', fontSize: '.8rem' }}>
                    {valor}
                </div>
            </div>
        )
    }

    function renderRightContainer() {
        const timeOfTravel = etilometerConfig.number_of_tests !== undefined ? etilometerConfig.time_of_travel + ' min' : '-'
        const numberOfTests = etilometerConfig.number_of_tests !== undefined ? etilometerConfig.number_of_tests + ' Testes' : '-'
        const randMinTime = etilometerConfig.rand_min_time !== undefined ? etilometerConfig.rand_min_time + ' min' : '-'

        return (
            <div className='rightContainer'>
                <div style={{ color: 'white', fontWeight: 'bold', fontSize: '1.5rem' }}>
                    Informações Gerais
                </div>

                <div style={{ padding: '2%' }} />

                <LoadingDiv loading={update} style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
                        {renderOption('ID do Dispositivo', stored.device.esp_id)}
                        {renderOption('ID do Sensor', stored.device.sensor_id.substring(0, 11))}
                        {renderOption('Última Leitura do Etilômetro', relatedSensorData.last_read ?? 'N/A')}
                        {renderOption('Número de Testes Realizados', relatedSensorData.number_of_tests ?? 'N/A')}
                        {renderOption('Versão do Firmware', stored.software_version)}
                        {renderOption('Data de Instalação do Sensor', formatTimestamp(stored.installation_date))}
                        <div style={{ padding: '15px' }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                        {renderOption('Modo de Comunicação', getParameter('comm_type', etilometerConfig.comm_type, true))}
                        {renderOption('Modo de Operação', getParameter('operation_mode', etilometerConfig.operation_mode, true))}
                        {renderOption('Tempo de Viagem Randômico', timeOfTravel)}
                        {renderOption('Número de Testes Randômicos', numberOfTests)}
                        {renderOption('Tempo Mínimo de Testes Randômicos', randMinTime)}
                    </div>
                </LoadingDiv>

                <div />
                <div />
            </div>
        )
    }

    return (
        <div className='MainContainer' style={{ justifyContent: 'space-between' }}>
            <TopBar />

            <div className='mainInfoContainer'>
                {renderLeftContainer()}
                {renderRightContainer()}
            </div>

            <div />
        </div>
    )
}